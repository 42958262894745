import React from 'react'
import Background from '../Elements/Landing/Landing'

const Homepage = () => {
  return (
    <>
        <Background />
    </>
  )
}

export default Homepage