import React from 'react'
import { WorksContainer, WorkCard, BackBtn, WorksWrapper, CardsWrapper, WorksLanding, WorkIcon, WorkLink, WorkH2, WorkDescription, WorkGithub, IconWrapper } from './WorksStyles'
import { FaGithub } from 'react-icons/fa';
import {HiChevronDoubleLeft} from "react-icons/hi";
import builtVideo from '../../../Videos/built-llc-video.mp4';
import motivationVideo from '../../../Videos/motivation-app-video.mp4';
import redditVideo from '../../../Videos/reddit-lite-video.mp4';

const Works = () => {
  return (
    <WorksContainer>
      <WorksWrapper>
        <BackBtn to='/'>
          <HiChevronDoubleLeft />
        </BackBtn>
        <WorksLanding>
          React / Redux / Javascript 
        </WorksLanding>
        <CardsWrapper>
          <WorkCard>
            <IconWrapper>
              <WorkLink href='//builtinvestments.com' targets='_blank' aria-label='Built Investments'>              
                <WorkIcon autoPlay loop muted src={builtVideo} type='video/mp4' />  
              </WorkLink>
            </IconWrapper>
            <WorkH2>
              Built Investments
            </WorkH2>
            <WorkDescription>
              A commissioned project for Built Investments LLC for their real estate marketing and customer outreach.
            </WorkDescription>
            <WorkGithub>
              <FaGithub />
            </WorkGithub>
          </WorkCard>
          <WorkCard>
            <IconWrapper>
              <WorkLink href='//motivation-app-54313.web.app' targets='_blank' aria-label='Motivation App'>
                <WorkIcon autoPlay loop muted src={motivationVideo} type='video/mp4' />               
              </WorkLink>
            </IconWrapper>
            <WorkH2>
              Motivation App
            </WorkH2>
            <WorkDescription>
              A daily goals app using React & Redux. Utilizing 3 APIs to also provide the weather
              and a quote of the day.
            </WorkDescription>
            <WorkGithub href='//www.github.com/jnboutte/motivation-app' targets='_blank' aria-label='Github'>
              <FaGithub />
            </WorkGithub>
          </WorkCard>
          <WorkCard>
            <IconWrapper>
              <WorkLink href='//reddit-lite-19017.web.app' targets='_blank' aria-label='Reddit'>
                <WorkIcon autoPlay loop muted src={redditVideo} type='video/mp4'/>                
              </WorkLink>
            </IconWrapper>
            <WorkH2>
              Reddit Lite
            </WorkH2>
            <WorkDescription>
              A Reddit clone utilizing Redux with Reddit's API.
            </WorkDescription>
            <WorkGithub href='//www.github.com/jnboutte/Reddit-lite' targets='_blank' aria-label='Github'>
              <FaGithub />
            </WorkGithub>
          </WorkCard>
        </CardsWrapper>
      </WorksWrapper>
    </WorksContainer>
  )
}

export default Works