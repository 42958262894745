import styled, {keyframes} from 'styled-components';
import { Link } from 'react-router-dom';

export const AboutContainer = styled.div`
    background-color: black;
    width: 100%;
    padding: 0;
    margin: 0;
    height: 100%;
`;

export const AboutWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
`;


export const BackBtn = styled(Link)`
    position: sticky;
    text-decoration: none;
    top: 50px;
    padding-left: 50px;
    align-self: flex-start;
    font-size: 2rem;
    color: rgb(37, 174, 193);
    z-index: 10;

    @media screen and (max-width: 540px) {
        top: 30px;
        padding-left: 30px;
    }
`;

const textIn = keyframes`
0%{
    opacity: 0;
}
50%{
    color: rgb(37, 174, 193);
}
75%{
    color: rgb(37, 174, 193);
}
100%{
    opacity: 1;
}
`;

export const About1 = styled.div`
    color: white;
    height: 100vh;
    width: 100%;
    background-color: black;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-self: center;
    text-align: center;
    font-size: 64px;
    animation: ${textIn} 3s ease-in-out;
    margin-top: -50px;

    @media screen and (max-width: 540px) {
        font-size: 40px;
        padding: 0px 20px;
        text-align: center;
        margin-top: -30px;
    }
`;

export const About2 = styled.div`
    height: 80vh;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-top: 30px;
`;

export const About3 = styled.div`
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    @media screen and (max-width: 540px) {
        height: 100%;
    }
`;

export const ImageWrapper = styled.div`
    display: flex;
    height: 100vh;
    width: 100%;
    flex-direction: row;
    justify-content: space-around;
    overflow: hidden;

    @media screen and (max-width: 540px) {
        flex-direction: column;
    }

`;

export const Image1 = styled.img`
    height: 350px;
    align-self: center;
    padding: 0;
    margin: 0;
    border-bottom: 4px groove rgb(37, 174, 193);
    border-left: 4px groove rgb(37, 174, 193);
    filter: grayscale(.5);
    opacity: .8;
    overflow: hidden;

    @media screen and (max-width: 1300px) {
        height: 300px;
    }

    @media screen and (max-width: 950px) {
        height: 250px;       
    }

    @media screen and (max-width: 540px) {
        height: 30vh;    
    }
`;

export const Image2 = styled.img`
    height: 350px;
    border-bottom: 4px groove rgb(37, 174, 193);
    border-right: 4px groove rgb(37, 174, 193);
    align-self: center;
    padding: 0;
    margin: 0;
    filter: grayscale(.5);
    opacity: .8;
    overflow: hidden;
    
    @media screen and (max-width: 1300px) {
        height: 300px;
    }

    @media screen and (max-width: 950px) {
        height: 250px;       
    }

    @media screen and (max-width: 540px) {
        height: 30vh;
        margin-top: -100px;
    }
    
`;

export const TextWrapper = styled.h4`
    color: white;
    position: relative;
    display: flex;
    flex-direction: column;
    font-weight: lighter;
    text-align: justify;
    justify-content: center;
    align-self: center;
    height: 100%;
    width: 80%;

    @media screen and (max-width: 540px) {
        font-size: 16px;
        padding: 20px;
        
    }
`;

export const P1 = styled.p`
    width: 50%;
    font-size: 24px;
    letter-spacing: 1px;
    word-spacing: 1px;
    align-self: center;
    text-indent: 20px;
    padding-bottom: 10px;

    @media screen and (max-width: 540px) {
        font-size: 16px;
        width: 100%;
        word-spacing: .25px;
    }
`;

export const P2 = styled.p`
    width: 50%;
    font-size: 24px;
    letter-spacing: 1px;
    word-spacing: 1px;
    align-self: center;
    text-indent: 20px;
    padding-bottom: 10px;

    @media screen and (max-width: 540px) {
        font-size: 16px;
        width: 100%;
        word-spacing: .25px;
    }
`;

export const P3 = styled.p`
    width: 50%;
    font-size: 24px;
    letter-spacing: 1px;
    word-spacing: 1px;
    align-self: center;
    text-indent: 20px;
    padding-bottom: 10px;

    @media screen and (max-width: 540px) {
        font-size: 16px;
        width: 100%;
        word-spacing: .25px;
    }
`;

export const P4 = styled.p`
    width: 50%;
    font-size: 24px;
    letter-spacing: 1px;
    word-spacing: 1px;
    align-self: center;
    text-indent: 20px;
    padding-bottom: 10px;

    @media screen and (max-width: 540px) {
        font-size: 16px;
        width: 100%;
        word-spacing: .25px;
    }
`;

export const P5 = styled.p`
    width: 50%;
    font-size: 24px;
    letter-spacing: 1px;
    word-spacing: 1px;
    align-self: center;
    padding-bottom: 10px;
    color: rgb(37, 174, 193, .8);

    @media screen and (max-width: 540px) {
        font-size: 16px;
        width: 100%;
        word-spacing: .25px;
    }
`;


export const Resume = styled.a`
    width: 100px;
    background-color: white;
    text-align: center;
    color: black;
    align-self: center;
    border-radius: 10px;
    text-decoration: none;
    transition: all 0.2s ease-in-out;

    &:hover {
        background-color: rgb(37, 174, 193, .8);
        border: 1px solid white;
        cursor: pointer;
        transition: all 0.2s ease-in-out;
    }

`;

