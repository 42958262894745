import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Homepage from './Homepage/Homepage';
import './App.css';
import About from './Elements/Pages/About/About';
import Contact from './Elements/Pages/Contact/Contact';
import Works from './Elements/Pages/Works/Works';

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path='/' element={<Homepage/>} />
        <Route path='/about' element={<About/>} />
        <Route path='/works' element={<Works/>} />
        <Route path='/contact' element={<Contact/>} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
