import styled, {keyframes} from 'styled-components';
import {Link} from 'react-router-dom';

const side2side = keyframes`
    0%{
        left: 15vw;
    }
    100%{
        left: 60vw;
    }
`;

export const TapAnywhere = styled.div`
    display: none;

    @media screen and (max-width: 540px) {
        position: absolute;
        display: block;
        z-index: 5;
        background-color: transparent;
        color: rgb(37, 174, 193);
        font-size: 20px;
        justify-content: center;
        align-content: center;
        bottom: 20px;
        cursor: pointer;
        animation: ${side2side} 4s ease-in-out;
        animation-iteration-count: infinite;
        animation-direction: alternate;
    }       
`;

export const LandingContainer = styled.div`
    height: 100vh;
    width: 100vw;
    background-color: black;
`;

export const LandingWrapper = styled.div`
    display: grid;
    height: 100vh;
    width: 100vw;
    grid-template-columns: 1fr 2fr;
    grid-template-rows: 1fr 1fr;
    grid-column-gap: 100px;
`;

export const TextWrapper = styled.div`

    display: flex;
    background-color: transparent;
    position: relative;
    top: 20vh;
    height: 100%;
    width: fit-content;
    flex-direction: column;
    justify-content: center;
    align-self: center;
    grid-column: 2 / 3;
    padding-left: 100px;

    @media screen and (max-width: 280px) {
        top: 200px;
    }
    
    @media screen and (max-width: 380px) {
        left: -50px;
    }

    @media screen and (max-width: 540px) {
        left: -30px;
        padding-left: 0;
        padding-right: 30px;
    }

    @media screen and (max-width: 1028px) {
        left: -30px;
        padding-left: 0;
    }
`;

export const BgWrapper = styled.div`
    display: flex;
    flex-direction: column;
    grid-column: 1 / 2;
    height: 100%;
    width: 100%;

`;

const wordIn = keyframes`
0% {
    opacity: 0;
}
100% {
    opacity: 1;
}
`;

export const Hello = styled(Link)`
    color: rgb(37, 174, 193);
    text-decoration: none;
    font-size: 150px;
    width: auto;
    letter-spacing: 10px;
    position: relative;
    z-index: 2;
    animation: ${wordIn} 2s forwards ease-in-out;
    
    @media screen and (min-width: 541px) {
    &:hover span {
        display: none;
    }

    &:hover:before {
        content: "About";
        color: white;
        cursor: pointer;   
    }
    }


    @media screen and (max-width: 380px) {
        font-size: 50px;
    }

    @media screen and (max-width: 540px) {
        font-size: 90px;
        display: flex;
        flex-direction: column;
        opacity: .5;
        :after {
            content: "About";
            margin-top: -20px;
            margin-bottom: 10px;
            font-size: 20px;
            color: white;
            opacity: 1;
        }
    }
`;

export const IAm = styled(Link)`
    color: rgb(37, 174, 193);
    text-decoration: none;
    font-size: 150px;
    position: relative;
    letter-spacing: 10px;
    word-spacing: 5px;
    z-index: 2;
    animation: ${wordIn} 2s forwards ease-in-out;

    @media screen and (min-width: 541px) {
    &:hover span {
        display: none;
    }

    &:hover:before {
        content: "Works";
        color: white;
        cursor: pointer;   
    }
    }

    @media screen and (max-width: 380px) {
        font-size: 100px;
    }

    @media screen and (max-width: 540px) {
        font-size: 90px;
        display: flex;
        flex-direction: column;
        opacity: .5;
        :after {
            content: "Works";
            margin-top: -20px;
            margin-bottom: 10px;
            font-size: 20px;
            color: white;
            opacity: 1;
        }
    }
`;

export const Jeremy = styled(Link)`
    color: rgb(37, 174, 193);
    text-decoration: none;
    font-size: 150px;
    letter-spacing: 10px;
    position: relative;
    z-index: 2;
    animation: ${wordIn} 2s forwards ease-in-out;

    @media screen and (min-width: 541px) {
        &:hover span {
            display: none;
        }

        &:hover:before {
            content: "Contact";
            color: white;
            cursor: pointer;   
        }
    }
    

    @media screen and (max-width: 380px) {
        font-size: 100px;
    }

    @media screen and (max-width: 540px) {
        font-size: 90px;
        display: flex;
        flex-direction: column;
        opacity: .5;
        :after {
            content: "Contact";
            margin-top: -20px;
            font-size: 20px;
            color: white;
            opacity: 1;
        }
    }
`;

const BgIn = keyframes`
0% {
    opacity: 0;
}
100% {
    opacity: 1;
}
`;

export const BgImage= styled.img`
    filter: grayscale(.9);
    overflow: hidden;
    position: fixed;
    bottom: 10px;
    left: 50px;
    z-index: 1;
    height: 120%;
    animation: ${BgIn} .5s forwards ease-in;

    @media screen and (max-width: 380px) {
        bottom: 0;
        left: 0;
        height: 120%;
    }
`;
