import styled, {keyframes} from 'styled-components';
import { Link } from 'react-router-dom';

export const ContactContainer = styled.div`
    background-color: black;
    width: 100%;
    height: 100%;
    padding: 0;
    margin: 0;
`;

export const BackBtn = styled(Link)`
    position: sticky;
    text-decoration: none;
    top: 50px;
    padding-left: 50px;
    align-self: flex-start;
    font-size: 2rem;
    color: rgb(37, 174, 193);
    z-index: 10;

    @media screen and (max-width: 540px) {
        top: 30px;
        padding-left: 30px;
    }
`;

export const ContactWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
`;

const textIn = keyframes`
0%{
    opacity: 0;
}
50%{
    color: rgb(37, 174, 193);
}
75%{
    color: rgb(37, 174, 193);
}
100%{
    opacity: 1;
}
`;

export const ContactLanding = styled.div`
    width: 100%;
    height: 100vh;
    margin-top: -50px;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 80px;
    animation: ${textIn} 3s ease-in-out;

    @media screen and (max-width: 540px) {
        font-size: 40px;
        padding: 0px 20px;
        text-align: center;
    }
`;

export const FormWrapper = styled.div`
    height: 100vh;
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 2fr;
    grid-template-rows: 1fr 2fr;
`;

export const TextWrapper = styled.div`
    grid-column: 1 / 2;
    grid-row: 1 / -1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    padding: 20px;
    padding-left: 200px;

    @media screen and (max-width: 540px) {
        grid-column: 1 / -1;
        grid-row: 1 / 2;
        padding: 10px;
    }
`;

export const Text = styled.h2`
    color: white;
    font-weight: lighter;
    font-size: 24px;
    letter-spacing: 1px;
    word-spacing: 1px;
    padding-left: 10px;
    border-bottom: 4px groove rgb(37, 174, 193);
    border-left: 4px groove rgb(37, 174, 193);
    border-bottom-left-radius: 5px;

    @media screen and (max-width: 540px) {
        border-left: 0;
        border-top: 4px groove rgb(37, 174, 193);
        border-radius: 0;
        font-size: 20px;
        padding: 10px;
    }
`;

export const ContactForm = styled.form`
    grid-column: 2 / 3;
    grid-row: 1 / -1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px;
    height: 100%;
    width: 100%;

    @media screen and (max-width: 540px) {
        grid-column: 1 / -1;
        grid-row: 2 / 3;
        font-size: 20px;
        padding: 10px;
    }
`;

export const InputGroup = styled.div`
    width: 50%;
    height: auto;
    padding: 20px 0px;
    font-weight: lighter;

    @media screen and (max-width: 540px) {
        padding: 5px 0px;
    }
`;

export const InputName = styled.label`
    color: rgb(37, 174, 193);

    @media screen and (max-width: 540px) {
        font-size: 16px;
    }
`;

export const ContactInput = styled.input`
    background-color: white;
    border-radius: 5px;
    border: 0;
    outline: 0;
    width: 100%;
    height: 30px;
    font-size: 24px;

    &:focus {
        border: 2px solid rgb(37, 174, 193);
    }

    @media screen and (max-width: 540px) {
        height: 16px;
        font-size: 14px;
    }
`;

export const ContactTextArea = styled.textarea`
    background-color: white;
    border: 0;
    outline: 0;
    border-radius: 5px;
    grid-row: 1 / -1;
    resize: none;
    width: 100%;
    font-size: 20px;
    height: 120px;

     &:focus {
        border: 2px solid rgb(37, 174, 193);
    }

    @media screen and (max-width: 540px) {
        height: 60px;
    }
`;

export const SubmitWrap = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: black;
    height: auto;
    width: 30%;
    padding-top: 40px;

    @media screen and (max-width: 540px) {
        width: 20%;
        height: 24px;
        padding-bottom: 20px;
        padding-top: 20px;
    }
`;

export const SubmitButton = styled.button`
    border-radius: 5px;
    background-color: white;
    width: 100%;
    padding: 5px;
    align-self: center;
    cursor: pointer;
    border: none;
    box-shadow: 0 0 15px 0 rgb(42, 55, 56, .8);

    &:hover {
        background-color: rgb(37, 174, 193);
    }

    
`;