import styled, {keyframes} from 'styled-components';
import { Link } from 'react-router-dom';

export const WorksContainer = styled.div`
    height: 100%;
    width: 100%;
    background-color: black;
`;

export const WorksWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
`;


export const BackBtn = styled(Link)`
    position: sticky;
    text-decoration: none;
    top: 50px;
    padding-left: 50px;
    align-self: flex-start;
    font-size: 2rem;
    color: rgb(37, 174, 193);
    z-index: 10;
`;

const textIn = keyframes`
0%{
    opacity: 0;
}
50%{
    color: rgb(37, 174, 193);
}
75%{
    color: rgb(37, 174, 193);
}
100%{
    opacity: 1;
}
`;

export const WorksLanding = styled.div`
    color: white;
    height: 100vh;
    margin-top: -50px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-self: center;
    text-align: center;
    font-size: 64px;
    animation: ${textIn} 3s ease-in-out;
`;

export const CardsWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-left: 50px;

    @media screen and (max-width: 850px) {
        padding: 0;
    }
`;

export const WorkCard = styled.div`
    color: #fff;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr 1fr;
    height: 100%;
    width: 100%;
    
`;

export const IconWrapper = styled.a`
    grid-column: 2 / 4;
    grid-row: 1 / -1;
    height: 100%;
    width: 100%;
    padding-left: 100px;
    display: flex;
    justify-content: center;


    @media screen and (max-width: 850px) {
        grid-column: 1 / -1;
        grid-row: 3 / 4;
        padding: 0;
    }
`;

export const WorkLink = styled.a`
  align-self: center;
  display: flex;
  justify-content: center;
`;

export const WorkIcon = styled.video`
    height: 80%;
    width: 80%;
    cursor: pointer;
    align-self: center;
    padding-bottom: 10px;
`;

export const WorkH2 = styled.h2`
    font-size: 2.5rem;
    color: rgb(37, 174, 193);
    letter-spacing: 1.4px;
    text-transform: uppercase;;
    padding-top: 100px;
    text-align: center;

    @media screen and (max-width: 850px) {
        grid-column: 1 / -1;
        grid-row: 1 / 2;
    }
`;

export const WorkDescription = styled.h4`
    grid-column: 1 / 2;
    grid-row: 2 / 3;
    align-self: center;
    text-align: center;
    font-weight: lighter;

    @media screen and (max-width: 850px) {
        grid-column: 1 / -1;
        grid-row: 2 / 3;
    }
`;

export const WorkGithub = styled.a`
    grid-column: 1 / 2;
    grid-row: 3 / 4;
    color: white;
    font-size: 1rem;
    text-align: center;
    cursor: pointer;

    &:hover {
        color: rgb(37, 174, 193);
        transition: 0.3s ease-out;
    }

    @media screen and (max-width: 850px) {
        grid-column: 1 / -1;
        grid-row: 2 / 3;
    }
`;