import React from 'react';
import { LandingContainer, BgImage, LandingWrapper, TextWrapper, Hello, IAm, Jeremy, BgWrapper } from './LandingStyles';
import BgPic from '../../Images/bg-pic.jpg';

const Background = () => {

  return (
    <LandingContainer >
      <LandingWrapper>
        <TextWrapper>
          <Hello to="/about">
            <span>Hello.</span>            
          </Hello>
          <IAm to="/works">
            <span>I am</span>
          </IAm>
          <Jeremy to="contact">
            <span>Jeremy</span>
          </Jeremy>
        </TextWrapper>
        <BgWrapper>
          <BgImage src={BgPic} />
        </BgWrapper>
      </LandingWrapper>
    </LandingContainer>
  )
}

export default Background