import React, {useRef} from 'react';
import emailjs from '@emailjs/browser';
import {HiChevronDoubleLeft} from "react-icons/hi";
import { ContactContainer, BackBtn, ContactForm, ContactWrapper, FormWrapper, ContactLanding, 
    InputGroup, InputName, ContactInput, ContactTextArea, SubmitWrap, TextWrapper, Text, SubmitButton } from './ContactStyles';

const Contact = () => {

    const form = useRef();

    const sendEmail = (e) => {
      e.preventDefault();
  
      emailjs.sendForm('service_1slsagd', 'contact_form', form.current, 'user_yrN7yNcYIyZU1KVM2KExi')
        .then((result) => {
            console.log(result.text);
        }, (error) => {
            console.log(error.text);
        });
    };

  return (
    <ContactContainer>
        <ContactWrapper>
            <BackBtn to='/'>
                <HiChevronDoubleLeft />
            </BackBtn>
            <ContactLanding>
                Ready to make something amazing? Because I am. 
            </ContactLanding>
            <FormWrapper>
                <TextWrapper>
                    <Text>
                        I'm looking for oppurtunities to work with companies / agencies / individuals.
                        I want to contribute my creativity and critical thinking skills to create and progress
                        companies while also learning and continuing to grow.
                        If you'd like to get in contact with me to talk about working together, I'd love to talk,
                        just fill out the form and I'll get back to you as soon as possible.
                    </Text>
                </TextWrapper>
                <ContactForm ref={form} onSubmit={sendEmail}>
                    <InputGroup>
                        <InputName>Name</InputName>
                        <ContactInput type='text' name='name'/>
                    </InputGroup>
                    <InputGroup>
                        <InputName>Email</InputName>
                        <ContactInput type='text' name='email'/>
                    </InputGroup>
                    <InputGroup>
                        <InputName>Subject</InputName>
                        <ContactInput type='text' name='subject'/>
                    </InputGroup>
                    <InputGroup>
                        <InputName>Message</InputName>
                        <ContactTextArea type='text' name='message'/>
                    </InputGroup>
                    <SubmitWrap>
                        <SubmitButton>Submit</SubmitButton>
                    </SubmitWrap>
                </ContactForm>
            </FormWrapper>
        </ContactWrapper>
    </ContactContainer>
  )
}

export default Contact