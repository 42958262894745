import React from 'react'
import { About1, About2, About3, BackBtn, AboutContainer, Resume, AboutWrapper, TextWrapper, Image2, ImageWrapper, P1, P2, P3, P4, P5, Image1 } from './AboutStyles'
import aboutPic1 from '../../../Images/about-pic2.jpg'
import aboutPic2 from "../../../Images/about-pic.jpg";
import {HiChevronDoubleLeft} from "react-icons/hi";
import resume from '../../../Images/Resume.pdf';

const About = () => {
  return (
    <AboutContainer>
        <AboutWrapper>
            <BackBtn to='/'>
                <HiChevronDoubleLeft />
            </BackBtn>
            <About1>
                I'm a Front end developer, who enjoys creating efficient &
                creative designs. 
            </About1>
            <About2>
                <ImageWrapper>
                    <Image1 src={aboutPic1}/>
                    <Image2 src={aboutPic2} />
                </ImageWrapper>
            </About2>
            <About3>
                <TextWrapper>
                    <P1>
                    Hi, I'm Jeremy Boutte. I'm an individual who is constantly looking to challenge my creativite ability.
                    I am passionate about applying my creativity to critical thinking in order to solve problems
                    and present products in new ways.
                    </P1>
                    <P2>In the past and currently I have worked within different organizations in photography and videography in order
                    to apply my creativity.
                    However, I have sparked a passion for front end developing because it has allowed me to push myself even further.
                    My goal above all else is to continuously develop my thinking creatively and critically. 
                    The subtle nuances needed in coding of towing the line between structured problem solving and out of the box thinking is encapsulating.
                    </P2>
                    <P3>
                    I'm very family oriented so spending time with them is a big part of my life. Outside of work and family, I love to go to the gym and to play trading card games   
                    Both allow me either physical or mental stimuli in order to come back to my work with a clean and refreashed mind.
                    </P3>
                    <P4> Currently I am looking for new oppurtunites whether that be with a freelance client base or within an organization to apply
                    my skills as well as further them in order to create new and exciting products.</P4>
                    <P5>You can download my resume to learn more about me.</P5>
                    <Resume href= {resume} download>
                        Resume
                    </Resume>
                </TextWrapper> 
            </About3>
        </AboutWrapper>
    </AboutContainer>
  )
}

export default About